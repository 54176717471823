import { SVGProps, Ref, forwardRef } from 'react'

const SvgIdentityBadge = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.75 5.25h-7.5a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5h19.5a1.5 1.5 0 0 0 1.5-1.5V6.75a1.5 1.5 0 0 0-1.5-1.5h-7.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.25 4.5a2.25 2.25 0 0 0-4.5 0v3a.75.75 0 0 0 .75.75h3a.75.75 0 0 0 .75-.75v-3ZM5.625 13.875a2.625 2.625 0 1 0 5.25 0 2.625 2.625 0 0 0-5.25 0v0ZM3.75 21.75a4.5 4.5 0 1 1 9 0h-9ZM14.25 12.75h4.5M14.25 15.75h6"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgIdentityBadge)
export default ForwardRef
